import React, {useContext,useEffect} from 'react';
import { Typography } from 'antd';
// import { AuthContext } from '../context/AuthContext';
import { Redirect } from 'react-router-dom'
const { Title } = Typography;

const HelpPage = (props) => {
    // const { token,settoken,userdata,setuserdata,isLoggedIn,bc,setbc } = useContext(AuthContext);

    // useEffect((props,setbc) => {
    //     if (isLoggedIn()) {
    //         setbc(['Help'])
    //     } else {
    //         return <Redirect to="/login" />;
    //     }
    // }, [])


    return(
        <div>
            <Title>Help Page</Title>
        </div>
    )
}


export default HelpPage;