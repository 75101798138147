import React, {useState,useEffect,useContext} from "react";
import { Route,Redirect } from 'react-router-dom';
import { AuthContext } from "../context/AuthContext";

function PrivateRoute({ component: Component, ...rest }) {
  
const {isAuthenticated,setisAuthenticated} = useContext(AuthContext);

  return(
    <Route 
        {...rest} 
        render={(props) => 
        (
            isAuthenticated ? (
                <Component {...props} />
            ) :
            (
                <Redirect to="/loginform" />
            )
        )}
    />
  );
}

export default PrivateRoute;