// react
import React, { useState,useContext } from "react";
import { Route, Switch, NavLink,BrowserRouter } from 'react-router-dom';
// react
// ant
import { Layout, Menu, Card,Typography  } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  UserOutlined,
  ContactsOutlined,
  UploadOutlined,
  LoginOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
// ant

//import local
import "./Menu.css";
import JSUDashBoardPage from '../components/JSUDashBoardPage';
import NotFoundPage from '../components/NotFoundPage';
import HelpPage from '../components/HelpPage';
import Loginform from '../components/Loginform';
import Logoutform from '../components/Logoutform';
import ContactPage from '../components/Contact';
import masterinsurance from '../components/Insurance';
import masterinsurancejatuhtempo from '../components/InsuranceJatuhTempo';
import masteruser from '../components/MasterUser';


import PrivateRoute from '../components/PrivateRoute';
//import local

const { Header, Sider, Content } = Layout;
const { Title } = Typography;

const MenuF = props => {
    const [collapsed,setcollapsed] = useState(false);

    const toggle = () => {
        setcollapsed( !collapsed )
    }

    
    return(
        <div>
            <BrowserRouter>
                <div>
                    <Layout>
                        <Sider trigger={null} collapsible collapsed={collapsed}>
                            <div className="logo" />
                            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                                <Menu.Item key="1" icon={<HomeOutlined />}>
                                    <NavLink to='/' activeClassName='is-active'>Home</NavLink>
                                </Menu.Item>
                                <Menu.Item key="2" icon={<UploadOutlined />}>
                                    <NavLink to='/masterinsurance' activeClassName='is-active'>Master Insurance</NavLink>
                                </Menu.Item>
                                <Menu.Item key="3" icon={<UploadOutlined />}>
                                    <NavLink to='/masterinsurancejatuhtempo' activeClassName='is-active'>Jatuh Tempo</NavLink>
                                </Menu.Item>
                                <Menu.Item key="4" icon={<LoginOutlined />}>
                                    <NavLink to='/loginform' activeClassName='is-active'>Login</NavLink>
                                </Menu.Item>
                                <Menu.Item key="5" icon={<LogoutOutlined />}>
                                    <NavLink to='/logout' activeClassName='is-active'>Logout</NavLink>
                                </Menu.Item>
                                <Menu.Item key="6" icon={<ContactsOutlined />}>
                                    <NavLink to='/contact' activeClassName='is-active'>Contact</NavLink>
                                </Menu.Item>
                            </Menu>
                        </Sider>
                        <Layout className="site-layout">
                            <Header className="site-layout-background" style={{ padding: 0 }}>
                                <Card>
                                    <Title>Jerindo Sari Utama</Title>
                                </Card>
                                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: toggle,
                                })}
                            </Header>
                            < Content className="site-layout-background" style={{margin: '24px 16px', padding: 24, minHeight: 280,}}>
                                <div>
                                    <Switch>
                                        <PrivateRoute path='/' component={JSUDashBoardPage} exact={true} />
                                        <PrivateRoute path='/help' component={HelpPage} />
                                        <PrivateRoute path='/masterinsurance' component={masterinsurance} />
                                        <PrivateRoute path='/masteruser' component={masteruser} />
                                        <PrivateRoute path='/masterinsurancejatuhtempo' component={masterinsurancejatuhtempo} />
                                        <Route path='/loginform' component={Loginform} />
                                        <PrivateRoute path='/logout' component={Logoutform} />
                                        <Route path='/contact' component={ContactPage} />

                                        <Route component={NotFoundPage} />
                                    </Switch>
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                </div>
                
                
                
                
            </BrowserRouter>    
        </div>
        
    );
};

export default MenuF;


