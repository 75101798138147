import React, {useContext,useEffect} from 'react';
import { Redirect } from 'react-router-dom'
import { Form, Icon, Input, Button, Checkbox,message } from 'antd';
import { AuthContext } from '../context/AuthContext';

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
};

const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
};

const NormalLogoutForm = (props) => {
    const {isAuthenticated,setisAuthenticated} = useContext(AuthContext);

    const onFinish = values => {
        console.log('Success:', values);
        // console.log('Username : ',values.username)
        // console.log('Password : ',values.password)
        // console.log('username : ',token)
        localStorage.removeItem('token')

        if ( isAuthenticated ) {
            setisAuthenticated(false)
            message.success("User logged out")
        } else {
            message.warning("User logged out FAIL")
        }

    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Logout
                </Button>
            </Form.Item>

        </Form>
    )

}

export default NormalLogoutForm;