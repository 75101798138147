import React, {useState,useEffect,useContext} from "react";
import { InsuranceContext } from "../context/InsuranceContext";
import { AuthContext } from "../context/AuthContext";
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import {
    Table,
    Popconfirm,
    Button,

    Form,
    Input,
    Typography,
    message,
    DatePicker,
    Spin,
    Tooltip,
    Cascader,
    Select,
    Row,
    Col,
    Checkbox,
    AutoComplete,
    Collapse,
    Divider,
  } from 'antd';
  import { ReloadOutlined } from '@ant-design/icons';

const { Panel } = Collapse
const { Title } = Typography;
const { TextArea } = Input;

const MasterInsuranceJatuhTempo  = props => {

    const { 
        masterinsurance,setmasterinsurance,
        masterinsuranceid,setmasterinsuranceid,
        masterinsuranceloading,setmasterinsuranceloading,
        masterinsuranceeditmode,setmasterinsuranceeditmode,
        labeleditmasterinsurance,setlabeleditmasterinsurance,
        masterinsurance_no_polis,setmasterinsurance_no_polis,
        masterinsurance_nama_asuransi,setmasterinsurance_nama_asuransi,
        masterinsurance_nama_tertanggung,setmasterinsurance_nama_tertanggung,
        masterinsurance_keterangan,setmasterinsurance_keterangan,
        masterinsurance_tgl_mulai_asuransi,setmasterinsurance_tgl_mulai_asuransi,
        masterinsurance_tgl_selesai_asuransi,setmasterinsurance_tgl_selesai_asuransi,
        masterinsurance_premi,setmasterinsurance_premi,
        masterinsurance_nilai_pertanggungan,setmasterinsurance_nilai_pertanggungan
    } = useContext(InsuranceContext);

    const {isAuthenticated,setisAuthenticated} = useContext(AuthContext);

    
    const prosesData = async () => {
        setmasterinsuranceloading(true)
        // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        let res = await axios.get("/asuransi_jatuh_tempo_30_hari")
        // setMastergroupbarang(res.data.data)
        
        // console.log('data :::>',(res.data.data))
        console.log('mgb : ',res.data.data)
        const datatmp = []
        for (let i = 0; i < (res.data.data.length); i++) {
            datatmp.push({
                key: res.data.data[i].id,
                id: res.data.data[i].id,
                no_polis: res.data.data[i].no_polis,
                nama_asuransi: res.data.data[i].nama_asuransi,
                nama_tertanggung: res.data.data[i].nama_tertanggung,
                keterangan: res.data.data[i].keterangan,
                tgl_mulai_asuransi: moment(res.data.data[i].tgl_mulai_asuransi).format("YYYY-MM-DD"),
                tgl_selesai_asuransi: moment(res.data.data[i].tgl_selesai_asuransi).format("YYYY-MM-DD"),
                premi: res.data.data[i].premi,
                nilai_pertanggungan: res.data.data[i].nilai_pertanggungan,

                // printable
                premi_printable : (res.data.data[i].premi).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                nilai_pertanggungan_printable : (res.data.data[i].nilai_pertanggungan).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
            });
        }
        setmasterinsurance(datatmp)
        setmasterinsuranceloading(false)
    }

    useEffect(()=>{        
        // if (isLoggedIn()) {
        //     setbc(['Master','Sales'])
        //     prosesData();
        // } else {
        //     return <Redirect to="/login" />;
        // }
        // if (!isLoggedIn()) {
        //     return <Redirect to='/login' />
        // }

        prosesData();
    },[])



    const handleEdit = (item,dta) => {
        // console.log("item edited = ",item, " , data = ",dta,", kode : ",item.kode)
        setmasterinsurance_no_polis(item.no_polis)
        setmasterinsurance_nama_asuransi(item.nama_asuransi)
        setmasterinsurance_nama_tertanggung(item.nama_tertanggung)
        setmasterinsurance_keterangan(item.keterangan)
        setmasterinsurance_tgl_mulai_asuransi(moment(item.tgl_mulai_asuransi,"YYYY-MM-DD"))
        setmasterinsurance_tgl_selesai_asuransi(moment(item.tgl_selesai_asuransi,"YYYY-MM-DD"))
        setmasterinsurance_premi(item.premi)
        setmasterinsurance_nilai_pertanggungan(item.nilai_pertanggungan)

        setmasterinsuranceid(item.id)

        setmasterinsuranceeditmode(true)
        setlabeleditmasterinsurance('Save')
    }

    const handleDelete = (item,dta) => {
        // console.log("item delete = ",item, " , data = ",dta,", kode : ",item.kode)

        // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        axios
            .delete("/asuransi/"+item.id)
            .then((res)=>{
                message.success("record di master insurance berhasil didelete")
                prosesData()

                setmasterinsurance_no_polis("")
                setmasterinsurance_nama_asuransi("")
                setmasterinsurance_nama_tertanggung("")
                setmasterinsurance_keterangan("")
                setmasterinsurance_tgl_mulai_asuransi(moment())
                setmasterinsurance_tgl_selesai_asuransi(moment())
                setmasterinsurance_premi(0)
                setmasterinsurance_nilai_pertanggungan(0)

                setmasterinsuranceid('')
                setmasterinsuranceeditmode(false)
                setlabeleditmasterinsurance('New Record')

            })
            .catch((e)=>{
                console.log("Error :",e)
                message.error("record di master insurance tidak berhasil didelete")

                // display message login error
                // setErrorMessage("Tidak dapat mendelete dari master group barang")
                // setTimeout(() => {
                //     setErrorMessage('')
                // }, 2000);

                setmasterinsurance_no_polis("")
                setmasterinsurance_nama_asuransi("")
                setmasterinsurance_nama_tertanggung("")
                setmasterinsurance_keterangan("")
                setmasterinsurance_tgl_mulai_asuransi(moment())
                setmasterinsurance_tgl_selesai_asuransi(moment())
                setmasterinsurance_premi(0)
                setmasterinsurance_nilai_pertanggungan(0)

                setmasterinsuranceid('')
                setmasterinsuranceeditmode(false)
                setlabeleditmasterinsurance('New Record')
            })
    }

    const columns = [
        {
            title: 'Nomor Polis',
            // width: 100,
            dataIndex: 'no_polis',
            key: 'no_polis',
            width: '0.5%',
        },
        {
            title: 'Nama Asuransi',
            // width: 100,
            dataIndex: 'nama_asuransi',
            key: 'nama_asuransi',
            width: '0.5%',
        },
        {
            title: 'Nama Tertanggung',
            // width: 100,
            dataIndex: 'nama_tertanggung',
            key: 'nama_tertanggung',
            width: '0.5%',
        },
        {
            title: 'Keterangan',
            // width: 100,
            dataIndex: 'keterangan',
            key: 'keterangan',
            width: '0.5%',
        },
        {
            title: 'Tanggal Mulai',
            // width: 100,
            dataIndex: 'tgl_mulai_asuransi',
            key: 'tgl_mulai_asuransi',
            width: '0.5%',
        },
        {
            title: 'Tanggal Selesai',
            // width: 100,
            dataIndex: 'tgl_selesai_asuransi',
            key: 'tgl_selesai_asuransi',
            width: '0.5%',
        },
        {
            title: 'Premi',
            // width: 100,
            dataIndex: 'premi_printable',
            key: 'premi',
            width: '0.5%',
        },
        {
            title: 'Nilai Pertanggungan',
            // width: 100,
            dataIndex: 'nilai_pertanggungan_printable',
            key: 'nilai_pertanggungan',
            width: '0.5%',
        },
        
    ];

    // const WrappedRegistrationForm = Form.create({ name: 'register' })(MasterUserForm);
    const WrappedRegistrationForm = ()=>(<MasterInsuranceForm/>);

    return (
        <div>
            <Title>Master Insurance</Title>

            <br/><br/>


            <div style={{ marginBottom: 16 }}>
                <Button onClick={prosesData} icon={<ReloadOutlined />} >
                    Reload
                </Button>
                <Divider />
                <Table 
                    pagination={ {pageSize: 5} }
                    loading={masterinsuranceloading} columns={columns} dataSource={masterinsurance} scroll={{ x: 1500, y: 300 }} />
            </div>
        </div>

        
    );
}

export default MasterInsuranceJatuhTempo;

const MasterInsuranceForm = (props) => {

    const { 
        masterinsurance,setmasterinsurance,
        masterinsuranceid,setmasterinsuranceid,
        masterinsuranceloading,setmasterinsuranceloading,
        masterinsuranceeditmode,setmasterinsuranceeditmode,
        labeleditmasterinsurance,setlabeleditmasterinsurance,
        masterinsurance_no_polis,setmasterinsurance_no_polis,
        masterinsurance_nama_asuransi,setmasterinsurance_nama_asuransi,
        masterinsurance_nama_tertanggung,setmasterinsurance_nama_tertanggung,
        masterinsurance_keterangan,setmasterinsurance_keterangan,
        masterinsurance_tgl_mulai_asuransi,setmasterinsurance_tgl_mulai_asuransi,
        masterinsurance_tgl_selesai_asuransi,setmasterinsurance_tgl_selesai_asuransi,
        masterinsurance_premi,setmasterinsurance_premi,
        masterinsurance_nilai_pertanggungan,setmasterinsurance_nilai_pertanggungan
    } = useContext(InsuranceContext);

    const {isAuthenticated,setisAuthenticated} = useContext(AuthContext);

    // const { getFieldDecorator } = props.form;

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const formTailLayout = {
        wrapperCol: {
            xs: {
            span: 24,
            offset: 0,
            },
            sm: {
            span: 16,
            offset: 8,
            },
        },
    };
    
    // const formItemLayout = {
    //     labelCol: {
    //         xs: { span: 24 },
    //         sm: { span: 8 },
    //     },
    //     wrapperCol: {
    //         xs: { span: 24 },
    //         sm: { span: 16 },
    //     },
    // };

    // const tailFormItemLayout = {
    //     wrapperCol: {
    //         xs: {
    //         span: 24,
    //         offset: 0,
    //         },
    //         sm: {
    //         span: 16,
    //         offset: 8,
    //         },
    //     },
    // };

    const prosesData = async () => {
        setmasterinsuranceloading(true)
        let res = await axios.get("/asuransi_jatuh_tempo_30_hari")
        // setMastergroupbarang(res.data.data)
        
        // console.log('data :::>',(res.data.data))
        console.log('mgb : ',res.data.data)
        const datatmp = []
        for (let i = 0; i < (res.data.data.length); i++) {
            datatmp.push({
                key: res.data.data[i].id,
                id: res.data.data[i].id,
                no_polis: res.data.data[i].no_polis,
                nama_asuransi: res.data.data[i].nama_asuransi,
                nama_tertanggung: res.data.data[i].nama_tertanggung,
                keterangan: res.data.data[i].keterangan,
                tgl_mulai_asuransi: moment(res.data.data[i].tgl_mulai_asuransi).format("YYYY-MM-DD"),
                tgl_selesai_asuransi: moment(res.data.data[i].tgl_selesai_asuransi).format("YYYY-MM-DD"),
                premi: res.data.data[i].premi,
                nilai_pertanggungan: res.data.data[i].nilai_pertanggungan,

                // printable
                premi_printable : (res.data.data[i].premi).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                nilai_pertanggungan_printable : (res.data.data[i].nilai_pertanggungan).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
            });
        }
        setmasterinsurance(datatmp)
        setmasterinsuranceloading(false)
    }

    

    // useEffect(()=>{   
        // prosesData();     
        // if (isLoggedIn()) {
        //     setbc(['Master','Sales'])
        //     prosesData();
        // } else {
        //     return <Redirect to="/login" />;
        // }
    // },[])

    const handleCancel = () => {
        console.log('cancel clicked')
        setmasterinsurance_no_polis("")
        setmasterinsurance_nama_asuransi("")
        setmasterinsurance_nama_tertanggung("")
        setmasterinsurance_keterangan("")
        setmasterinsurance_tgl_mulai_asuransi(moment())
        setmasterinsurance_tgl_selesai_asuransi(moment())
        setmasterinsurance_premi(0)
        setmasterinsurance_nilai_pertanggungan(0)

        setmasterinsuranceid('')
        setmasterinsuranceeditmode(false)
        setlabeleditmasterinsurance('New Record')
    }
    // const handleCancel = () => {
    //     console.log('cancel clicked')
    //     setmasteruserusername('')
    //     setmasteruserpassword('')
    //     setmasteruserid('')
    //     setmasterusereditmode(false)
    //     setlabeleditmasteruser('New Record')

    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const values = await form.validateFields();
            console.log('Success:', values);
            console.log('id : ',masterinsuranceid)

            const item = {
                no_polis: values.no_polis,
                nama_asuransi: values.nama_asuransi,
                nama_tertanggung: values.nama_tertanggung,
                keterangan: values.keterangan,
                tgl_mulai_asuransi: values.tgl_mulai_asuransi.format("YYYY-MM-DD"),
                tgl_selesai_asuransi: values.tgl_selesai_asuransi.format("YYYY-MM-DD"),
                premi: values.premi,
                nilai_pertanggungan: values.nilai_pertanggungan
            }

            if (masterinsuranceid==="") {
                // berarti add new user
                axios
                    .post("/asuransi",item)
                    .then((res)=>{
                        message.success("record berhasil ditambahkan di master asuransi")

                        // console.log('Dapat data : ',res.data.data.user)
                        // console.log('Dapat token : ',res.data.data.token)

                        prosesData()

                        setmasterinsurance_no_polis("")
                        setmasterinsurance_nama_asuransi("")
                        setmasterinsurance_nama_tertanggung("")
                        setmasterinsurance_keterangan("")
                        setmasterinsurance_tgl_mulai_asuransi(moment())
                        setmasterinsurance_tgl_selesai_asuransi(moment())
                        setmasterinsurance_premi(0)
                        setmasterinsurance_nilai_pertanggungan(0)

                        setmasterinsuranceid('')
                        setmasterinsuranceeditmode(false)
                        setlabeleditmasterinsurance('New Record')
                    })
                    .catch((e)=>{
                        console.log("Error :",e)

                        message.error("record tidak berhasil ditambahkan di master insurance")
                        prosesData()

                        setmasterinsurance_no_polis("")
                        setmasterinsurance_nama_asuransi("")
                        setmasterinsurance_nama_tertanggung("")
                        setmasterinsurance_keterangan("")
                        setmasterinsurance_tgl_mulai_asuransi(moment())
                        setmasterinsurance_tgl_selesai_asuransi(moment())
                        setmasterinsurance_premi(0)
                        setmasterinsurance_nilai_pertanggungan(0)

                        setmasterinsuranceid('')
                        setmasterinsuranceeditmode(false)
                        setlabeleditmasterinsurance('New Record')
                        
                    })
            }  else {
                const itemupdate = {
                    no_polis: values.no_polis,
                    nama_asuransi: values.nama_asuransi,
                    nama_tertanggung: values.nama_tertanggung,
                    keterangan: values.keterangan,
                    tgl_mulai_asuransi: values.tgl_mulai_asuransi.format("YYYY-MM-DD"),
                    tgl_selesai_asuransi: values.tgl_selesai_asuransi.format("YYYY-MM-DD"),
                    premi: values.premi,
                    nilai_pertanggungan: values.nilai_pertanggungan
                }

                console.log('yang maudiupdate ',masterinsuranceid)
                console.log('isi item' , itemupdate)

                axios
                    .patch(`/asuransi/${masterinsuranceid}`,itemupdate)
                    .then((res)=>{
                        // props.history.push('/mastergroupbarang');
                        message.success("record di master asuransi berhasil diupdate")
                        prosesData()

                        setmasterinsurance_no_polis("")
                        setmasterinsurance_nama_asuransi("")
                        setmasterinsurance_nama_tertanggung("")
                        setmasterinsurance_keterangan("")
                        setmasterinsurance_tgl_mulai_asuransi(moment())
                        setmasterinsurance_tgl_selesai_asuransi(moment())
                        setmasterinsurance_premi(0)
                        setmasterinsurance_nilai_pertanggungan(0)

                        setmasterinsuranceid('')
                        setmasterinsuranceeditmode(false)
                        setlabeleditmasterinsurance('New Record')
                    })
                    .catch((e)=>{
                        console.log("Error :",e)
                        message.error("Tidak dapat merubah data di master asuransi")

                        setmasterinsurance_no_polis("")
                        setmasterinsurance_nama_asuransi("")
                        setmasterinsurance_nama_tertanggung("")
                        setmasterinsurance_keterangan("")
                        setmasterinsurance_tgl_mulai_asuransi(moment())
                        setmasterinsurance_tgl_selesai_asuransi(moment())
                        setmasterinsurance_premi(0)
                        setmasterinsurance_nilai_pertanggungan(0)

                        setmasterinsuranceid('')
                        setmasterinsuranceeditmode(false)
                        setlabeleditmasterinsurance('New Record')
                    })

            }

        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    }
    

    // ===
    const [form] = Form.useForm();
    
    const config1 = {
        rules: [{ type: 'object', required: true, message: 'Masukkan Tanggal Mulai Asuransei' }],
    };
    const config2 = {
        rules: [{ type: 'object', required: true, message: 'Masukkan Tanggal Selesai Asuransei' }],
    };

    const dateFormat = 'YYYY-MM-DD';

    return (
        <div>
            <   Form form={form} 
                    name="dynamic_rule"
                    initialValues={{
                        'no_polis': `${masterinsurance_no_polis}`,
                        'nama_asuransi': `${masterinsurance_nama_asuransi}`,
                        'nama_tertanggung': `${masterinsurance_nama_tertanggung}`,
                        'keterangan': `${masterinsurance_keterangan}`,
                        'tgl_mulai_asuransi': moment(`${masterinsurance_tgl_mulai_asuransi}`),
                        'tgl_selesai_asuransi': moment(`${masterinsurance_tgl_selesai_asuransi}`),
                        'premi': `${masterinsurance_premi}`,
                        'nilai_pertanggungan': `${masterinsurance_nilai_pertanggungan}`,
                    }}
            >
                <Form.Item
                    {...formItemLayout}
                    name="no_polis"
                    label="Nomor Polis"
                    rules={[
                        {
                            required: true,
                            message: 'Tolong masukkan nomor polis',
                        },
                    ]}
                    >
                    <Input placeholder="Masukkan nomor polis"  />
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    name="nama_asuransi"
                    label="Nama Asuransi"
                    rules={[
                        {
                            required: true,
                            message: 'Tolong masukkan nama asuransi',
                        },
                    ]}
                    >
                    <Input placeholder="Masukkan nama asuransi" />
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    name="nama_tertanggung"
                    label="Nama Tertanggung"
                    rules={[
                        {
                            required: true,
                            message: 'Tolong masukkan nama tertanggung',
                        },
                    ]}
                    >
                    <Input placeholder="Masukkan nama tertanggung" />
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    name="keterangan"
                    label="Keterangan"
                    rules={[
                        {
                            required: true,
                            message: 'Tolong masukkan keterangan',
                        },
                    ]}
                    >
                    <TextArea  rows={4} placeholder="Masukkan keterangan" />
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    name="tgl_mulai_asuransi"
                    label="Tanggal Mulai"
                    {...config1}
                >
                    <DatePicker  format={dateFormat} />
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    name="tgl_selesai_asuransi"
                    label="Tanggal Selesai"
                    {...config2}
                    >
                    <DatePicker  format={dateFormat} />
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    name="premi"
                    label="Premi"
                    rules={[
                        {
                            required: true,
                            message: 'Tolong masukkan premi asuransi',
                        },
                    ]}
                    >
                    <Input placeholder="Masukkan premi asuransi" />
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    name="nilai_pertanggungan"
                    label="Nilai Pertanggungan"
                    rules={[
                        {
                            required: true,
                            message: 'Tolong masukkan nilai pertanggungan',
                        },
                    ]}
                    >
                    <Input placeholder="Masukkan nilai pertanggungan" />
                </Form.Item>


                <Form.Item {...formTailLayout}>

                    <Button type="primary" onClick={handleSubmit}>
                        {labeleditmasterinsurance}
                    </Button>
                    {
                        masterinsuranceeditmode && <Button type="danger" onClick={handleCancel}>Cancel</Button>
                    }
                </Form.Item>
            </Form>
        </div>
    );
    
}