import React, {useContext,useEffect} from 'react';
import { Typography } from 'antd';
// import { AuthContext } from '../context/AuthContext';
// import { AuthContext } from '../context/AuthContext';
// import { Redirect } from 'react-router-dom'
// import checkAuth from './checkAuth';
const { Title } = Typography;

const JSUGudangDashBoardPage = (props) => {
    // const { token,settoken,userdata,setuserdata,isLoggedIn,bc,setbc } = useContext(AuthContext);
    // const { isLoggedIn } = useContext(AuthContext);

    // if (!isLoggedIn()) {
    //     return <Redirect to='/login' />
    // } 
    useEffect(() => {
        // console.log(props)
        // setbc(['Home'])
    }, [])



    return (
        <div>
            <Title level={4}>No important news</Title>
        </div>
    );
}

export default JSUGudangDashBoardPage;