import React, {useState,useEffect,useContext} from "react";
import { Redirect } from 'react-router-dom'
import { Form, Input, Button, Checkbox, message } from 'antd';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  
  const LoginForm = (props) => {
    const {isAuthenticated,setisAuthenticated} = useContext(AuthContext);


    const onFinish = values => {
      // console.log('Success:', values);
      // console.log('Username : ',values.username)
      // console.log('Password : ',values.password)

      axios
      .post("/users/login",values)
      .then((res)=>{
          const userRes = res.data.data.user;
          const tokenRes = res.data.data.token;
          const asuransi = res.data.data.user.asuransi;
          const masteradmin = res.data.data.user.masteradmin;

          localStorage.setItem('token',tokenRes)
          // settoken(tokenRes)
          // setuserdata(userRes)
          // console.log('DATANYA : ',res.data.data)
          // console.log('isi flag asuransi : ',asuransi)

          // console.log("x : ",res.data.data.user.masteradmin)
          if (asuransi===1 || masteradmin===1) {
            message.success("User logged in")
            setisAuthenticated(true)
          } else {
            message.error("User is not allowed to access Master Insurance")
            setisAuthenticated(false)
          }
          

          // console.log('User : ',userRes)
      })
      .catch((e)=>{
          console.log("Error :",e)
    
          // display message login error
          message.error("Username or password incorrect")
          
      })

    };
  
    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    var token_from_storage = localStorage.getItem('token');
    console.log('Token-Storage : ',token_from_storage)
    axios
      .get("/users/token/"+token_from_storage)
      .then((res)=>{
          const userRes = res.data.data[0].username;
          const tokenRes = token_from_storage;
          const asuransi = res.data.data[0].asuransi;
          const masteradmin = res.data.data[0].masteradmin;

          if (asuransi===1 || masteradmin===1) {
            setisAuthenticated(true)
          } else {
            setisAuthenticated(false)
          }
      })
      .catch((e)=>{
          console.log("Error :",e)          
      })


    return isAuthenticated?(<p>User logged in</p>):
    (
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input placeholder="demo" />
        </Form.Item>
  
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password placeholder="demo2019" />
        </Form.Item>
  
        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
  
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  


}

export default LoginForm;
