import React, {useState,useEffect,useContext} from "react";
import { MasterUserContext } from "../context/MasterUserContext";
// import { AuthContext } from "../context/AuthContext";
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import {
    Table,
    Popconfirm,
    Button,

    Form,
    Input,
    Typography,
    message,
    Spin,
    Tooltip,
    Cascader,
    Select,
    Row,
    Col,
    Checkbox,
    AutoComplete,
    Collapse,
    Divider,
  } from 'antd';
 

const { Panel } = Collapse

const { Title } = Typography;

const MasterUser  = props => {

    const { 
        masteruser,setmasteruser,
        masteruserid,setmasteruserid,
        masteruserusername,setmasteruserusername,
        masteruserpassword,setmasteruserpassword,
        masteruserloading,setmasteruserloading,
        masterusereditmode,setmasterusereditmode,
        labeleditmasteruser,setlabeleditmasteruser
    } = useContext(MasterUserContext);

    // const {token,settoken,userdata,setuserdata,isLoggedIn} = useContext(AuthContext);

    
    const prosesData = async () => {
        setmasteruserloading(true)
        // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        let res = await axios.get("/users")
        // setMastergroupbarang(res.data.data)
        
        // console.log('data :::>',(res.data.data))
        console.log('mgb : ',res.data.data)
        const datatmp = []
        for (let i = 0; i < (res.data.data.length); i++) {
            datatmp.push({
                key: res.data.data[i].id,
                id: res.data.data[i].id,
                username: res.data.data[i].username,
                password: res.data.data[i].password,
            });
        }
        setmasteruser(datatmp)
        setmasteruserloading(false)
    }

    useEffect(()=>{        
        // if (isLoggedIn()) {
        //     setbc(['Master','Sales'])
        //     prosesData();
        // } else {
        //     return <Redirect to="/login" />;
        // }
        // if (!isLoggedIn()) {
        //     return <Redirect to='/login' />
        // }

        prosesData();
    },[])



    const handleEdit = (item,dta) => {
        // console.log("item edited = ",item, " , data = ",dta,", kode : ",item.kode)
        setmasteruserusername(item.username)
        setmasteruserpassword(item.password)
        setmasteruserid(item.id)
        setmasterusereditmode(true)
        setlabeleditmasteruser('Save')
    }

    const handleDelete = (item,dta) => {
        // console.log("item delete = ",item, " , data = ",dta,", kode : ",item.kode)

        // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        axios
            .delete("/users/"+item.id)
            .then((res)=>{
                message.success("record di master user berhasil didelete")
                prosesData()

                // setgnama('')
                // setgkode('')
                // setmastergroupbarangeditmode(false)
                // setmastergroupbarangid(-1)
                // setlabeleditmastergroupbarang('New Record')
                setmasteruserusername('')
                setmasteruserpassword('')
                setmasteruserid('')
                setmasterusereditmode(false)
                setlabeleditmasteruser('New Record')
            })
            .catch((e)=>{
                console.log("Error :",e)
                message.error("record di master user tidak berhasil didelete")

                // display message login error
                // setErrorMessage("Tidak dapat mendelete dari master group barang")
                // setTimeout(() => {
                //     setErrorMessage('')
                // }, 2000);

                setmasteruserusername('')
                setmasteruserpassword('')
                setmasteruserid('')
                setmasterusereditmode(false)
                setlabeleditmasteruser('New Record')
            })
    }

    const columns = [
        {
            title: 'Username',
            // width: 100,
            dataIndex: 'username',
            key: 'username',
            width: '40%',
        },
        {
            title: 'Password',
            // width: 100,
            dataIndex: 'password',
            key: 'password',
            width: '40%',
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: '20%',
            render: (text, record) =>
                masteruser.length >= 1 ? (
                    <div>
                        <Button type="primary" onClick={()=>handleEdit(text,record.key)} >Edit</Button>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(text,record.key)}>
                            <Button type="danger">Delete</Button>
                        </Popconfirm>
                    </div>
                ) : null,
        },
        
    ];

    // const WrappedRegistrationForm = Form.create({ name: 'register' })(MasterUserForm);
    const WrappedRegistrationForm = ()=>(<MasterUserForm/>);

    return (
        <div>
            <Title>Master User</Title>

            <br/><br/>
            <Collapse>
                <Panel header="Add Data" key="1">
                    <WrappedRegistrationForm />
                </Panel>
            </Collapse>


            <br/>
            <div style={{ marginBottom: 16 }}>
                <Button onClick={prosesData}>
                    Reload
                </Button>
                <Divider />
                <Table 
                    pagination={ {pageSize: 5} }
                    loading={masteruserloading} columns={columns} dataSource={masteruser} scroll={{ x: 1500, y: 300 }} />
            </div>
        </div>

        
    );
}

export default MasterUser;

const MasterUserForm = (props) => {

    const { 
        masteruser,setmasteruser,
        masteruserid,setmasteruserid,
        masteruserusername,setmasteruserusername,
        masteruserpassword,setmasteruserpassword,
        masteruserloading,setmasteruserloading,
        masterusereditmode,setmasterusereditmode,
        labeleditmasteruser,setlabeleditmasteruser } = useContext(MasterUserContext);
    
    const {token,settoken,userdata,setuserdata,isLoggedIn} = useContext(MasterUserContext);
    // const { getFieldDecorator } = props.form;

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const formTailLayout = {
        wrapperCol: {
            xs: {
            span: 24,
            offset: 0,
            },
            sm: {
            span: 16,
            offset: 8,
            },
        },
    };
    
    // const formItemLayout = {
    //     labelCol: {
    //         xs: { span: 24 },
    //         sm: { span: 8 },
    //     },
    //     wrapperCol: {
    //         xs: { span: 24 },
    //         sm: { span: 16 },
    //     },
    // };

    // const tailFormItemLayout = {
    //     wrapperCol: {
    //         xs: {
    //         span: 24,
    //         offset: 0,
    //         },
    //         sm: {
    //         span: 16,
    //         offset: 8,
    //         },
    //     },
    // };

    const prosesData = async () => {
        setmasteruserloading(true)
        let res = await axios.get("/users")
        // setMastergroupbarang(res.data.data)
        
        // console.log('data :::>',(res.data.data))
        console.log('mgb : ',res.data.data)
        const datatmp = []
        for (let i = 0; i < (res.data.data.length); i++) {
            datatmp.push({
                key: res.data.data[i].id,
                id: res.data.data[i].id,
                username: res.data.data[i].username,
                password: res.data.data[i].password,
            });
        }
        setmasteruser(datatmp)
        setmasteruserloading(false)
    }

    

    // useEffect(()=>{   
        // prosesData();     
        // if (isLoggedIn()) {
        //     setbc(['Master','Sales'])
        //     prosesData();
        // } else {
        //     return <Redirect to="/login" />;
        // }
    // },[])

    const handleCancel = () => {
        console.log('cancel clicked')
        setmasteruserusername('')
        setmasteruserpassword('')
        setmasteruserid('')
        setmasterusereditmode(false)
        setlabeleditmasteruser('New Record')

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const values = await form.validateFields();
            console.log('Success:', values);
            console.log('id : ',masteruserid)

            const item = {
                username : values.username.toLowerCase(),
                password : values.password
            }

            if (masteruserid==="") {
                // berarti add new user
                axios
                    .post("/users",item)
                    .then((res)=>{
                        message.success("record berhasil ditambahkan di master user")

                        console.log('Dapat data : ',res.data.data.user)
                        console.log('Dapat token : ',res.data.data.token)

                        prosesData()

                        setmasteruserusername('')
                        setmasteruserpassword('')
                        setmasteruserid('')
                        setmasterusereditmode(false)
                        setlabeleditmasteruser('New Record')
                    })
                    .catch((e)=>{
                        console.log("Error :",e)

                        message.error("record tidak berhasil ditambahkan di master user")
                        prosesData()

                        setmasteruserusername('')
                        setmasteruserpassword('')
                        setmasteruserid('')
                        setmasterusereditmode(false)
                        setlabeleditmasteruser('New Record')
                        
                    })
            }  else {
                const itemupdate = {
                    password : values.password
                }

                console.log('yang maudiupdate ',masteruserid)
                console.log('isi item' , item)
                axios
                    .patch(`/users/${masteruserid}`,itemupdate)
                    .then((res)=>{
                        // props.history.push('/mastergroupbarang');
                        message.success("record di master user berhasil diupdate")
                        prosesData()

                        setmasteruserusername('')
                        setmasteruserpassword('')
                        setmasteruserid('')
                        setmasterusereditmode(false)
                        setlabeleditmasteruser('New Record')
                    })
                    .catch((e)=>{
                        console.log("Error :",e)
                        message.error("Tidak dapat merubah data di master user")

                        setmasteruserusername('')
                        setmasteruserpassword('')
                        setmasteruserid('')
                        setmasterusereditmode(false)
                        setlabeleditmasteruser('New Record')
                    })

            }

        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    }
    
    const handleSubmit_old_1 = (e) => {
        e.preventDefault();
         props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values from form: ', values);
                console.log('id : ',masteruserid)

                const item = {
                    username : values.username.toLowerCase(),
                    password : values.password
                }

                axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

                if (masteruserid==="") {
                    // new record
                    axios
                        .post("/users",item)
                        .then((res)=>{
                            message.success("record berhasil ditambahkan di master user")
                            prosesData()

                            setmasteruserusername('')
                            setmasteruserpassword('')
                            setmasteruserid('')
                            setmasterusereditmode(false)
                            setlabeleditmasteruser('New Record')
                        })
                        .catch((e)=>{
                            console.log("Error :",e)

                            message.error("record tidak berhasil ditambahkan di master user")
                            prosesData()

                            setmasteruserusername('')
                            setmasteruserpassword('')
                            setmasteruserid('')
                            setmasterusereditmode(false)
                            setlabeleditmasteruser('New Record')
                            
                        })
                } else {
                    const itemupdate = {
                        password : values.password
                    }

                    console.log('yang maudiupdate ',masteruserid)
                    console.log('isi item' , item)
                    axios
                        .patch(`/users/${masteruserid}`,itemupdate)
                        .then((res)=>{
                            // props.history.push('/mastergroupbarang');
                            message.success("record di master user berhasil diupdate")
                            prosesData()

                            setmasteruserusername('')
                            setmasteruserpassword('')
                            setmasteruserid('')
                            setmasterusereditmode(false)
                            setlabeleditmasteruser('New Record')
                        })
                        .catch((e)=>{
                            console.log("Error :",e)
                            message.error("Tidak dapat merubah data di master currency 1")

                            setmasteruserusername('')
                            setmasteruserpassword('')
                            setmasteruserid('')
                            setmasterusereditmode(false)
                            setlabeleditmasteruser('New Record')
                        })
                }
            }

        })
    }


    // ===
    const [form] = Form.useForm();
    

    return (
        <div>
            <   Form form={form} 
                    name="dynamic_rule"
                    initialValues={{
                        'username': `${masteruserusername}`,
                        'password': `${masteruserpassword}`,
                      }}
            >
                <Form.Item
                    {...formItemLayout}
                    name="username"
                    label="Username"
                    rules={[
                        {
                            required: true,
                            message: 'Tolong masukkan username',
                        },
                    ]}
                    >
                    <Input placeholder="Masukkan username" disabled={masterusereditmode} />
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: true,
                            message: 'Tolong masukkan password',
                        },
                    ]}
                    >
                    <Input placeholder="Masukkan password" />
                </Form.Item>

                <Form.Item {...formTailLayout}>

                    <Button type="primary" onClick={handleSubmit}>
                        {labeleditmasteruser}
                    </Button>
                    {
                        masterusereditmode && <Button type="danger" onClick={handleCancel}>Cancel</Button>
                    }
                </Form.Item>
            </Form>
        </div>
    );
    
}