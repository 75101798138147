import React, { createContext ,useState,useEffect } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom'

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
    const [isAuthenticated,setisAuthenticated] = useState(false)
    
    return ( 
        <div>
            {<AuthContext.Provider value={ {isAuthenticated,setisAuthenticated} }>
                {props.children}
            </AuthContext.Provider>
            }
        </div>
    )
}

export default AuthContextProvider;