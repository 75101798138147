import React, { createContext ,useState } from 'react';
import moment from 'moment';

export const InsuranceContext = createContext();

const InsuranceContextProvider = (props) => {
    const [masterinsurance,setmasterinsurance] = useState([]);
    const [masterinsuranceid,setmasterinsuranceid] = useState("");
    const [masterinsuranceloading,setmasterinsuranceloading] = useState(false);
    const [masterinsuranceeditmode,setmasterinsuranceeditmode] = useState(false);
    const [labeleditmasterinsurance,setlabeleditmasterinsurance] = useState('New Record');
    
    const [masterinsurance_no_polis,setmasterinsurance_no_polis] = useState("");
    const [masterinsurance_nama_asuransi,setmasterinsurance_nama_asuransi] = useState("");
    const [masterinsurance_nama_tertanggung,setmasterinsurance_nama_tertanggung] = useState("");
    const [masterinsurance_keterangan,setmasterinsurance_keterangan] = useState("");
    const [masterinsurance_tgl_mulai_asuransi,setmasterinsurance_tgl_mulai_asuransi] = useState(moment());
    const [masterinsurance_tgl_selesai_asuransi,setmasterinsurance_tgl_selesai_asuransi] = useState(moment());
    const [masterinsurance_premi,setmasterinsurance_premi] = useState(0);
    const [masterinsurance_nilai_pertanggungan,setmasterinsurance_nilai_pertanggungan] = useState(0);
    
    return(
        <div>
            <InsuranceContext.Provider
                value={
                    {
                        masterinsurance,setmasterinsurance,
                        masterinsuranceid,setmasterinsuranceid,
                        masterinsuranceloading,setmasterinsuranceloading,
                        masterinsuranceeditmode,setmasterinsuranceeditmode,
                        labeleditmasterinsurance,setlabeleditmasterinsurance,
                        masterinsurance_no_polis,setmasterinsurance_no_polis,
                        masterinsurance_nama_asuransi,setmasterinsurance_nama_asuransi,
                        masterinsurance_nama_tertanggung,setmasterinsurance_nama_tertanggung,
                        masterinsurance_keterangan,setmasterinsurance_keterangan,
                        masterinsurance_tgl_mulai_asuransi,setmasterinsurance_tgl_mulai_asuransi,
                        masterinsurance_tgl_selesai_asuransi,setmasterinsurance_tgl_selesai_asuransi,
                        masterinsurance_premi,setmasterinsurance_premi,
                        masterinsurance_nilai_pertanggungan,setmasterinsurance_nilai_pertanggungan
                    }
                }
            >
                {props.children}
            </InsuranceContext.Provider>
        </div>
    );
};
 
export default InsuranceContextProvider;