import React from 'react';
import './App.css';

import Menu from './components/Menu';
import AuthContextProvider from './context/AuthContext';
import MasterUserContextProvider from "./context/MasterUserContext";
import InsuranceContextProvider from "./context/InsuranceContext";

const App = (props) => {
    return(
        <div className="App">
            <AuthContextProvider>
            <MasterUserContextProvider>
            <InsuranceContextProvider>
                <Menu />
            </InsuranceContextProvider>
            </MasterUserContextProvider>
            </AuthContextProvider>

        </div>
    )
}

export default App;