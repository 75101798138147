import React, { createContext ,useState } from 'react';

export const MasterUserContext = createContext();

const MasterUserContextProvider = (props) => {
    const [masteruser,setmasteruser] = useState([]);
    const [masteruserid,setmasteruserid] = useState("");
    const [masteruserusername,setmasteruserusername] = useState("");
    const [masteruserpassword,setmasteruserpassword] = useState("");
    const [masteruserloading,setmasteruserloading] = useState(false);
    const [masterusereditmode,setmasterusereditmode] = useState(false);
    const [labeleditmasteruser,setlabeleditmasteruser] = useState('New Record');

    return(
        <div>
            <MasterUserContext.Provider
                value={
                    {
                        masteruser,setmasteruser,
                        masteruserid,setmasteruserid,
                        masteruserusername,setmasteruserusername,
                        masteruserpassword,setmasteruserpassword,
                        masteruserloading,setmasteruserloading,
                        masterusereditmode,setmasterusereditmode,
                        labeleditmasteruser,setlabeleditmasteruser
                    }
                }
            >
                {props.children}
            </MasterUserContext.Provider>
        </div>
    );
};
 
export default MasterUserContextProvider;